@import "~bootstrap/scss/bootstrap";

// @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=K2D:wght@100&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// negative margin up because Bootstrap does not offer this in 5.0
$enable-negative-margins: true;
.mt-n70 {
  margin-top: -7rem !important;
}
.mt-n65 {
  margin-top: -6.5rem !important;
}
.mt-n60 {
  margin-top: -6rem !important;
}
.mt-n55 {
  margin-top: -5.5rem !important;
}
.mt-n50 {
  margin-top: -5rem !important;
}
.mt-n45 {
  margin-top: -4.5rem !important;
}
.mt-n40 {
  margin-top: -4rem !important;
}
.mt-n35 {
  margin-top: -3.5rem !important;
}
.mt-n30 {
  margin-top: -3rem !important;
}
.mt-n25 {
  margin-top: -2.5rem !important;
}
.mt-n20 {
  margin-top: -2rem !important;
}
.mt-n15 {
  margin-top: -1.5rem !important;
}
.mt-n10 {
  margin-top: -1rem !important;
}
.mt-n05 {
  margin-top: -0.5rem !important;
}

* {
  color: rgb(124, 124, 124);
}

body {
  font-family: "Saira", sans-serif;
  color: rgb(0, 0, 0);

  //* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  //black urban abstract GIF:
  // background-image: url('https://i.imgur.com/OQsplhT.gif');

  //white urban abstract GIF:
  // background-image: url('https://i.imgur.com/UvwBpkI.gif');

  //static background white base:
  // background-image: url(https://i.imgur.com/DyLUqdw.jpg);

  // background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  background-attachment: fixed;
  background-size: auto;
  background-position: center center;
}

h1,
h3 {
  color: #000;
}

a {
  color: #000;
}

p {
  color: #000;
}

div,
span {
  color: #000;
}

hr {
  color: #000;
  opacity: 1;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.blurry-text {
  outline-color: none;
  // outline-color: black;
  // text-shadow: 0 0 8px rgba(0,0,0,0.3);
}

.title {
  font-size: 50px;
}

// * * * * * * * * * * * * NAV BAR * * * * * * * * * * * *

.nb {
  letter-spacing: 3px;
}

.nav-list {
  letter-spacing: 3px;
  text-align: right;
  width: 200px;
  z-index: 50;
}

.darkmode--activated p {
  color: #fff;
}

.darkmode--activated div {
  color: rgb(0, 0, 0);
}

.darkmode--activated NavLinkProps {
  color: rgb(0, 0, 0);
}

// .darkmode--activated Nav, .darkmode--activated li {
//   color: #FFF;
// }

.Navbar.brand {
  z-index: 50;
}

// .top-nav {
//   display: none;
// }

@media only screen and (max-width: 890px) {
  .Navbar.Toggle {
    display: none;
  }

  .mainapp {
    width: 100vh;
  }

  .navlist {
    position: absolute;
    top: 5px;
    width: 50px;
  }
}

.navbar-toggler {
  border: none;
  position: absolute;
  right: 1px;
  top: 0px;
  z-index: 30;
  position: fixed;
  padding: 10px;
}

.darkmode--activated navbar-toggler {
  color: #000;
}

// * * * * * * * * * * * * H O M E * * * * * * * * * * * *

#home {
  margin-top: 20vh;
  margin-bottom: 20vh;
  text-align: center;
  color: #000000;
}

.apps {
  letter-spacing: 5px;
}

.outline {
  -webkit-text-stroke: 1.5px;
  -webkit-text-stroke-color: black;
  -webkit-text-fill-color: transparent;
}

.outline05 {
  -webkit-text-stroke: 0.5px;
  -webkit-text-stroke-color: black;
  -webkit-text-fill-color: transparent;
}

// * * * * * * * * * * * * A B O U T * * * * * * * * * * * *

#about {
  margin: 300px 0 600px 0;
  text-align: center;
  // display: flex;
  align-items: center;
  justify-content: center;
}

// * * * * * * * * * * * * S K I L L S * * * * * * * * * * * *

#skills {
  margin: 300px 0 600px 0;
  text-align: center;
}

.skills-group {
  line-height: 5px;
}

// * * * * * * * * * * * * P R O J E C T S * * * * * * * * * * * *

#projects {
  margin: 300px 0 600px 0;
  text-align: center;
}
.exp {
  text-align: justify;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
  border-color: transparent;
  outline: transparent;
}

.btn {
  border-color: transparent;
  box-shadow: transparent;
}

.project-title {
  color: #000;
}

// .project-title:hover {
//   color: transparent;
// }

.hidden-text {
  text-transform: none;
  margin-top: -15px;
}

.project1-image {
  height: 3em;
  width: 3.5em;
}

.readme1-image {
  height: 3em;
  width: 4em;
}

.project2-image {
  height: 3em;
  width: 3.5em;
}

.readme2-image {
  height: 3em;
  width: 4em;
}

.project3-image {
  height: 3em;
  width: 3.5em;
}

.readme3-image {
  height: 3em;
  width: 4em;
}
.project4-image {
  height: 3em;
  width: 3em;
}

.readme4-image {
  height: 3em;
  width: 4em;
}

.icon {
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  float: left, right, top, bottom;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  z-index: 1;
}

.icon:hover {
  z-index: 5;
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  transform: rotate(720deg);
  -webkit-transform: rotate(720deg);
  -moz-transform: rotate(720deg);
}

// * * * * * * * * * * * * S I D E   P R O J E C T S * * * * * * * * * * * *

#sideprojects {
  margin: 300px 0 600px 0;
  text-align: center;
}

// * * * * * * * * * * * * E X P E R I E N C E * * * * * * * * * * * *

#experience {
  margin: 300px 0 600px 0;
  text-align: center;
}

// * * * * * * * * * * * * I N T E R E S T S * * * * * * * * * * * *

#interests {
  margin: 300px 0 600px 0;
  text-align: center;
}

.interests-img {
  border-radius: 20%;

  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;

  float: left, right, top, bottom;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  z-index: 1000;
}

.interests-img:hover {
  z-index: 99;
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
}

// * * * * * * * * * * * * C O N T A C T * * * * * * * * * * * *

#contact {
  margin: 300px 0 600px 0;
  text-align: center;
}

.mail-image {
  height: 6em;
  width: 6em;
}

.acgh-image {
  height: 6em;
  width: 7em;
}

.linkedin-image {
  height: 6em;
  width: 6em;
}

// * * * * * * * * * * * * F O O T E R * * * * * * * * * * * *

.footer-text {
  text-align: center;
  margin-bottom: 50px;
  font-size: smaller;
}

// .darkmode-layer, .darkmode-toggle {
//   z-index: 100;
// }

// .interests-img {
// z-index: 1000;

// }
